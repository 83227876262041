body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body{overflow: initial !important;width: auto !important;}


body,html{background: #F4F7F6;}

.w1400{width: 1400px !important;margin: 0 auto;}
.padd_top66{padding-top: 66px !important;}
.mar_b10{margin-bottom: 10px;}


/* tabbar */
.tabbar{background-color: #fff;height: 66px;position: fixed;left: 0;top: 0;right: 0;z-index: 9999;}
.tabbar .w1400{display: flex;align-items: center;}
.tabbar .goreg{width: 94px;line-height: 32px;border-radius: 4px;background-color: #389589;text-align: center;color: #fff;cursor: pointer;}
.tabbar .menuBox{flex: 1;display: flex;align-items: center;}
.tabbar .menuBox .tabbar_item{padding: 0 10px 0 50px;}
.tabbar .menuBox .tabbar_item span{display: block;color: #999;margin: 6px 0;}
.tabbar .menuBox .tabbar_item p{color: #202121;font-size: 18px}
.tabbar .menuBox .tabbar_item::after{display: block;content: '';width: 12px;height: 2px;background-color: #389589;}
.tabbar .menuBox .tabbar_item.active p{font-weight: bold;color: #389589;}
.tabbar .uinfo{display: flex;align-items: center;}
.tabbar .uinfo .icon{display: block;width: 98px;}
.tabbar .uinfo .avatar{display: block;width: 36px;height: 36px;border-radius: 100%;margin-left: 8px;}

.Mask{position: fixed;left: 0;right: 0;bottom: 0;top: 0;z-index: 999;}
.Mcont{width: 200px;border-radius: 0 0 4px 4px;background-color: #fff;box-shadow: 0 1px 6px rgba(0,0,0,.12);position: absolute;right: 0;top: 66px;}
.Mcont p{padding-left: 24px;}
.Mcont .name{padding: 24px 0 24px 24px;border-bottom: 1px solid #DBDEDD;color: #202121;margin-bottom: 8px;}
.Mcont .p1{line-height: 48px;color: #000;cursor: pointer;}
.Mcont .p1.active{color: #fff;background-color: #389589;}

/* bottom */
.bottom_bar{
  background-color: #FCFCFC;
  overflow: hidden;
  margin-top:80px;
}
.bottom_bar .top{border-bottom: 1px solid #DBDEDD;display: flex;padding-bottom: 32px;padding-top: 40px;}
.bottom_bar .top .list{max-width: 810px;margin-right: auto;}
.bottom_bar .h2{font-weight: bold;color: #333;margin-top: 25px;}
.bottom_bar .flex{display: flex;flex-wrap: wrap;}
.bottom_bar .list .flex p{color: #333;margin-right: 40px;margin-top: 15px;cursor: pointer;font-weight: bold;}
.bottom_bar .flex img{display: block;width: 120px;height: 36px;margin: 15px 15px 0 0;}
.bottom_bar .w1400>.flex{display: flex;align-items: center;padding: 25px 0 22px;}
.bottom_bar .p1{flex: 1;color: #666;}
.bottom_bar .p2{color: #999;margin-left: 14px;}

/* 固定栏 */
.fixedbox{width: 80px;right: 0;background-color: #fff;z-index: 9;padding: 36px 0;}
.fixedbox .item{cursor: pointer;}
.fixedbox .item::after{display: block;content: '';width: 20px;height: 1px;background-color: #D9D9D9;margin: 20px auto;}
.fixedbox .item:last-child::after{display: none;}
.fixedbox .item img{display: block;width: 24px;margin: 0 auto;}
.fixedbox .item p{text-align: center;color: #333;margin-top: 7px;}


/* 首页 */
.indexBanner{height: 787px;width: 100%;background-color: #fff;position: relative;}
/* .indexBanner .ant-carousel{width: 1400px;margin: 0 auto;} */
.indexBanner .ant-carousel,.indexBanner .slick-slider,.indexBanner .slick-list,.indexBanner .slick-track,.indexBanner .item{height: 100%;}
.indexBanner .item{height: 787px;position: relative;}
.indexBanner .item .bg{display: block;width: 1400px;margin: 0 auto;}
.indexBanner .slideIcon{display: block;width: 44px;position: absolute;top: 50%;margin-top: -22px;z-index: 99;}
.indexBanner .slideIcon.prevIcon{left: 260px;}
.indexBanner .slideIcon.nextIcon{right: 260px;}
.maskk{position: absolute;left: 0;right: 0;bottom: 0;top: 0;background: rgba(0, 0, 0, .5);padding-top: 64px;}
.maskk .ckbtn{width: 120px;line-height: 36px;border-radius: 18px;text-align: center;background-color: #389589;color: #fff;margin-left: auto;cursor: pointer;}
.maskk h1{font-size: 40px;color: #fff;text-align: center;font-weight: bold;margin: 50px 0 15px;}
.maskk .p1{font-size: 16px;color: #fff;text-align: center;margin-bottom: 35px;}
.maskk .p1 span{color: #49C5B6;}
.maskk .flex{display: flex;align-items: center;justify-content: center;}
.maskk .flex .row{margin-right: 25px;}
.maskk .flex .row:last-child{margin-right: 0;}
.maskk .flex .row>p{color: #fff;text-align: center;margin-top: 15px;}
.maskk .ant-progress-circle .ant-progress-text{color: #fff;}
.maskk .flex .avatar{display: block;width: 42px;height: 42px;border: 1px solid #fff;border-radius: 100%;margin-right: 12px;}
.maskk .flex .avatar:last-child{margin-right: 0;}
.maskk .flexx{display: flex;align-items: center;margin-top: 110px;}
.maskk .flexx .roww{flex: 1;max-width: 332px;height: 120px;position: relative;background-image: url('./imgs/box.png');background-size: 100% 100%;margin-right: 24px;padding: 30px 26px 0;}
.maskk .flexx .roww:last-child{margin-right: 0;}
.maskk .flexx .avatar{display: block;width: 32px;height: 32px;border-radius: 100%;position: absolute;left: 42px;top: -16px;}
.maskk .flexx p{color: #fff;line-height: 24px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 3;overflow: hidden;}
.maskk .paibox{position: absolute;top: 200px;right: 350px;}
.maskk .paibox img{display: block;width: 200px;}
.maskk .paibox p{text-align: center;color: #fff;margin-top: 20px;}
.indexBanner .ant-carousel .slick-dots li button{opacity: 1;}
.indexBanner .ant-carousel .slick-dots li.slick-active button{background: #389589;}

.pjList{
  /* padding-bottom: 40px; */
  padding-bottom: 0px;
}
.pjList .tit{width: 180px;line-height: 52px;text-align: center;border-radius: 4px;
  background-color: #389589;font-size: 18px;font-weight: bold;color: #fff;margin-top: 66px;cursor: pointer;}
.pjList .tit1{width: 490px;line-height: 52px;text-align: center;border-radius: 4px;
  font-size: 18px;font-weight: bold;color: #fff;margin-top: 66px;cursor: pointer;}

.pjList .tit1 .checkRank{display: flex; margin-right: 10px; margin-top: 2px; flex-direction: column;position: absolute; right: 0;top: 0;}
.pjList .titheader{width: 100%; display: flex; justify-content: space-between;}
.pjList .titheader .tit{width: 180px;line-height: 52px;text-align: center;border-radius: 4px;
  background-color: #389589;font-size: 18px;font-weight: bold;color: #fff;margin-top: 66px;cursor: pointer;}
.pjList .select{ width: 180px;}
.pjList .tit1 .searchBtn{
  background-color: #389589; color: white;
}
.pjList .tit1  .activeBtn{
  background: rgb(244, 84, 60); color: white; border: none;
}
.pjList .ant-select:not(.ant-select-customize-input) .ant-select-selector{background-color: #389589; color: #000;}
.pjList .select .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{color: #000;}
.pjList .select .ant-select-single .ant-select-selector .ant-select-selection-item{font-size: 18px; color: white;}
.pjList .tit2{width: 180px;line-height: 52px;text-align: center;border-radius: 4px;
    font-size: 18px;font-weight: bold;color: black;margin-top: 66px;cursor: pointer;}
.pjList .box{display: flex;flex-wrap: wrap;}
.pjList .box .item{width: 332px;margin-right: 24px;border-radius: 4px;background-color: #fff;overflow: hidden;margin-top: 24px;padding-bottom: 20px;}
.infoLabel{margin-right: 24px;border-radius: 4px;background-color: #fff;overflow: hidden;padding-bottom: 20px;display: flex;flex-wrap: wrap;font-size: 1px;}
.infoP{margin-right: 24px;overflow: hidden;padding-bottom: 20px;display: flex;flex-wrap: wrap;height: 55px;}
.pjList .box .item:nth-child(4n){margin-right: 0;}
/* .pjList .item .pic{display: block;width: 100%;max-height: 186px;} */
.pjList .item .picbox{width: 100%;height: 186px;overflow: hidden;position: relative;}
.pjList .item .picbox .massk{position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: rgba(0, 0, 0, .5);display: flex;align-items: center;justify-content: center;opacity: 0;transition: .6s;}
.pjList .item .pic{display: block;width: 100%;height: 186px;transition: .5s;}
.pjList .box .item .picbox:hover .pic{transform: scale(1.4);}
.pjList .box .item .picbox:hover .massk{opacity: 1;}
.pjList .item .flexw{display: flex;flex-wrap: wrap;padding-left: 5px;}
.pjList .item .flexw p,.infoLabel p{
  border: 1px solid #202121;
  line-height: 26px;
  /* border-radius: 14px; */
  border-radius: 5px;
  padding: 0 12px;
  color: #333;
  margin-left: 15px;
  margin-top: 18px;
}

.pjList .item .uinfo{display: flex;align-items: center;padding: 20px;border-bottom: 1px solid #DBDEDD;}
.pjList .item .uinfo .lbox{flex: 1;overflow: hidden;}
.pjList .item .uinfo .lbox h1{font-size: 20px;color: #202121;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.pjList .item .uinfo .lbox p{color: #4A4D4B;margin-top: 10px;}
.pjList .item .uinfo .avatar{display: block;width: 46px;height: 46px;border-radius: 100%;}
.pjList .item .flex{display: flex;align-items: center;padding: 20px 20px 0;}
.pjList .item .time:first-child{border-right: 1px solid #DBDEDD;margin-right: 15px;}
.pjList .item .time .p1{color: #999;}
.pjList .item .time h1{font-size: 26px;font-weight: bold;color: #389589;margin-top: 9px;}
.pjList .item .time h1 span{font-size: 14px;}
.pjList .item .imgbox{flex: 1;display: flex;align-items: center;}
.pjList .item .imgbox img{display: block;width: 32px;height: 32px;border-radius: 100%;margin-right: 8px;}
.pjList .item .pfbtn{width: 56px;line-height: 32px;border-radius: 4px;text-align: center;background-color: #389589;color: #fff;}
.pjList .item .llscbox{display: flex;align-items: center;padding: 20px 0 0;}
.pjList .item .llscbox p{flex: 1;color: #000;display: flex;align-items: center;justify-content: center;border-right: 1px solid #DBDEDD;}
.pjList .item .llscbox p:last-child{border-right: 0;}
/* .pjList .item .llscbox p::after{display: block;content: '';width: 1px;height: 10px;background-color: #DBDEDD;margin: 0 12px;} */
.pjList .item .llscbox p:last-child::after{display: none;}



.adImg{display: block;width: 1400px;height: 240px;margin: 0 auto;border-radius: 4px;}

.searchBox{border-radius: 4px;background-color: #fff;padding: 32px;display: flex;margin-bottom: -26px;}
.searchBox span{display: block;flex: 1;height: 80px;border-radius: 4px;background-color: #EEF2F1;}
.searchBox p{margin-left: 20px;width: 280px;line-height: 80px;border-radius: 4px;
  text-align: center;background-color: #389589;font-size: 28px;font-weight: bold;color: #fff;cursor: pointer;}
.searchBoxClass{display: flex; justify-content: center;margin-top: 50px;margin-bottom:20px;background-color: #fff;line-height: 50px}
.searchBoxClass span{font-size: 20px;color: #000;font-weight: bold;margin-right: 30px}
.searchBoxClass span:hover{font-size: 20px;color: #389589;font-weight: bold;margin-right: 30px;cursor: pointer;border-bottom: 3px solid #389589;transition: all .3s}
.contentBox{ background-color: #fff; margin-top: 20px;}
.contentBox img{width: 100%}
.contentBox .itemTitle{font-size: 28px; font-weight: bold;margin-bottom:10px;padding-left: 20px;color: #389589; border-bottom: 3px solid #389589;padding-bottom: 10px}
.contentBox .moreTitle{ font-size: 20px; font-weight: bold; color:#4A4D4B;   border-bottom: 1px solid ;    padding-bottom: 4px}
.contentBox .moreTitle:hover{font-size: 20px; font-weight: bold;margin-bottom:10px;padding-left: 18px;color: #389589;}
.contentBox .itemImg{width:40%;height: 136px;}
.contentBox .autoBox{position: relative}
.contentBox .autoW{height: 515px;}
.contentBox .itemBtnBox{display: flex;justify-content: space-between;}
.itemBtnBox .itemBtn:hover{color: #389589; border: #389589 1px solid}
.contentBox .contentItem{margin-top: 20px;display: flex;justify-content: left}
.titleFlex{display: flex;justify-content: space-between;align-items: center;padding-right: 20px}
.contentBox .auto{padding: 20px; position: absolute;width: 100%;height: 80px;background-color: #000;margin-top: -80px;opacity: .4;color: #fff;font-size: 24px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
.contentBox .auto a{color: #fff;font-size: 24px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
.contentBox .textF{padding: 15px 20px;color:#444;display: flex; justify-content: space-between;flex-direction: column}
.contentBox .textBox{color:#444;text-indent: 2px; font-weight:bolder;font-size: 16px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;}
.contentBox .itemBtn{height: 45px;width: 100%;display: inline-block;border-radius: 3px;text-align: center;font-size: 20px;background: #ffffff;color: #2f2f2f; font-weight: 600;}
.contentBox .itemBtnActive{height: 45px;width: 100%;border:none;display: inline-block;border-radius: 3px;text-align: center;font-size: 20px;background: #389589;color: #fff; font-weight: 600;}
.contentBox .itemBtnActive:hover{border: #389589 1px solid;height: 45px;width: 100%;display: inline-block;border-radius: 3px;text-align: center;font-size: 20px;background: #389589;color: #fff; font-weight: 600;}
.contentBox .otherInfo{display: flex;justify-content: space-between;}
.recommended .prbox .flex1{padding: 10px; background-color: #fff;}
.recommended .prbox .flex1 h2{height:20px;width:75%;font-size: 20px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;}
.recommended .prbox .flex1 p{font-size: 16px;text-align: right;}
.articleBox{display: flex;position: relative;}
.articleBox .leftBox{width: 75%;}
.leftBox .leftItemBox{ display: flex;flex-wrap: wrap;justify-content: space-between;}
.articleBox .leftContentBox{width: 70%;background-color: #ffffff }
.leftContentBox{margin: 10px;margin-top: 0px}
.leftContentBox .leftContentBoxContent{padding: 10px;font: 16px/1 Tahoma, Helvetica, Arial, "微软雅黑",Georgia, serif, sans-serif ;}
.articleBox .leftContentBox .span{width: 80%!important; }
.articleBox .leftContentBox img{max-width: 100%!important; }
.articleBox .leftBox .contentItem{width: 48%; margin: 0px 10px 10px 10px; background-color:#fff;border-top: 1px solid #ccc;}
.articleBox .leftBox .contentItem .itemImg{width: 100%;height: 280px}
.articleBox .leftBox .contentItem .itemImg:hover{width: 100%;height: 280px;transform: scale(1.4);}
.articleBox .leftBoxSearch .contentItem{background-color: #ffffff;margin-bottom: 10px;margin-right: 10px; width: 32%;overflow: hidden}
.articleBox .leftBoxSearch .contentItem .itemImg{width: 100%;height: 280px}
.articleBox .leftBoxSearch .contentItem .itemImg:hover{width: 100%;height: 280px; transform: scale(1.2);}
.articleBox .titleBox{margin-bottom: 0px;border-bottom: 1px solid; font-size: 28px;padding: 10px; color: #333333;font-weight: bold;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 5;-webkit-box-orient: vertical;}
.articleBox .titleBoxh4{margin-bottom: 20px;padding: 10px; font-size: 22px; color: #333333;display: flex;justify-content: space-between}
.articleBox .titleBoxh4 span{font-size: 19px }
.articleBox .leftBoxSearch{width: 100%; flex-wrap: wrap;display: flex;justify-content: start;}
.rightBox{width: 28%;}
.articleTitle{height: 45px; font-size: 24px;padding-left: 20px;background-color: #389589; color: #ffffff; line-height: 45px}
.articleBox .rightBox .albumBox .albumItem{position: relative;overflow: hidden; background-color: #EEE;height: 126px;margin-top: 10px;margin-bottom: 10px}
.albumItem .name{position: absolute;bottom: 0px;background-color: #000; opacity:.4;width: 100%; line-height: 40px;padding-left: 20px;color:#ffffff;font-size: 20px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 1;-webkit-box-orient: vertical;}
.albumItem .img{width: 100%;height: 126px}
.albumItem .img:hover{width: 100%;height: 126px;transform: scale(1.2)}
/*.albumBox{background-color: #389589}*/
.itemContentBox .contentItem .textF{width: 60%;height: 136px; padding:0 20px; color:#444;display: flex; justify-content: space-between;flex-direction: column}
.contentItem .textF{width: 100%;height: calc(100% - 280px); padding: 15px 10px;color:#444;display: flex; justify-content: space-between;flex-direction: column}
.contentItem .textBox{ width:100%;color:#202121;text-indent: 2px; margin-bottom: 10px; font-size: 20px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
.leftItemBox .contentItem .textBox{ width:100%;color:#4A4D4B;text-indent: 2px; margin-bottom: 10px; font-size: 16px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;}
.contentItem .describe{ width:100%;color:#4A4D4B;text-indent: 2px; margin-bottom: 10px; font-size: 14px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;}
.contentItem .otherInfo{font-size: 14px;color:#4A4D4B; display: flex;justify-content: space-between; border-top: 1px solid;padding-top:10px;}
.leftBoxSearch .otherInfo{font-size: 16px;color:#4A4D4B; display: flex;justify-content: space-between; border-top: 1px solid;padding-top:10px;}
.leftItemBox .contentItem .otherInfo{font-size: 16px;color:#4A4D4B; display: flex;justify-content: space-between; border-top: 1px solid;padding-top:10px;}
.contentItem .title{width: 100%;padding-bottom: 10px; overflow: hidden;display: -webkit-box;-webkit-line-clamp: 5;-webkit-box-orient: vertical; cursor: pointer}
.detailOtherInfo{text-align: right;}
/* 作品详情 */
.menubox{background-color: #F1F4F3;}
.menubox .w1400{display: flex;align-items: center;}
.menubox p{color: #333;display: flex;align-items: center;line-height: 70px;cursor: pointer;}
.menubox p::after{display: block;content: '';margin: 0 15px 0 18px;width: 5px;height: 10px;background-image: url('./imgs/r2.png');background-size: 5px 10px;}
.menubox p:last-child::after{display: none;}

.sypjtimebox{width: 1400px;padding: 40px 0;border-radius: 4px;background-color: #389589;display: flex;align-items: center;justify-content: center;margin: 48px auto 0;}
.sypjtimebox img{display: block;width: 32px;margin-right: 20px;}
.sypjtimebox h1{font-size: 30px;font-weight: bold;color: #fff;display: flex;align-items: center;}
.sypjtimebox h1 p{font-size: 42px;color: #fff;}
.sypjtimebox h1 p span{font-size: 30px;}
.sypjtimebox .qpfbtn{width: 140px;line-height: 58px;border-radius: 29px;text-align: center;background-color: #fff;font-size: 24px;font-weight: bold;color: #389589;margin-left: 170px;}

.detailbox{display: flex;align-items: flex-start;padding: 36px 0 140px;}
.detailbox .lbox{width: 372px;margin-right: 24px;}
.detailbox .zpinfo{padding: 20px;border-radius: 4px;background-color: #fff;display: flex;align-items: center;margin-bottom: 24px;}
.detailbox .zpinfo .left{flex: 1;margin-right: 34px;}
.detailbox .zpinfo h1{font-size: 20px;color: #333;margin-bottom: 10px;padding-bottom: 10px;border-bottom: 1px solid #D9D9D9;}
.detailbox .zpinfo .flex,.tjitem .zzbox .flex{display: flex;align-items: center;}
.detailbox .zpinfo .flex p,.tjitem .zzbox .flex p{color: #666;}
.detailbox .zpinfo .flex .ze,.tjitem .zzbox .flex .ze{display: flex;align-items: center;}
.detailbox .zpinfo .flex .ze span,.tjitem .zzbox .flex .ze span{color: #333;}
.detailbox .zpinfo .flex .ze::after,.tjitem .zzbox .flex .ze::after{display: block;content: '';width: 1px;height: 10px;background-color: #D9D9D9;margin: 0 15px;}
.detailbox .zpinfo .avatar{display: block;width: 56px;height: 56px;border-radius: 100%;}
.wdinfo{border-radius: 4px;background-color: #fff;margin-bottom: 24px;overflow: hidden;padding-bottom: 26px;position: relative;}
.wdinfo .tit{margin: 20px 20px 56px;border-radius: 4px;line-height: 42px;text-align: center;background-color: #389589;font-size: 18px;font-weight: bold;color: #fff;}
.wdinfo .tit::after{display: block;content: '';width: 100%;height: 1px;background-color: #DBDEDD;position: absolute;left: 0;top: 82px;}
.wdinfo .flex{display: flex;align-items: center;padding-left: 24px;margin-top: 26px;}
.wdinfo .flex img{display: block;width: 16px;margin-right: 18px;}
.wdinfo .flex p{color: #333;}
.wdinfo .flwabox{display: flex;flex-wrap: wrap;}
.wdinfo .flwabox p{border: 1px solid #202121;line-height: 26px;border-radius: 5px;padding: 0 12px;color: #333;margin-left: 20px;margin-top: 20px;}
.wdinfo .imgbox{display: flex;align-items: center;margin: 0 20px;}
.wdinfo .imgbox .avatar{display: block;width: 36px;height: 36px;border-radius: 100%;margin-right: 13px;}
.tjitem{border-bottom: 1px solid #D9D9D9;padding: 20px;}
.tjitem:last-child{border-bottom: 0;}
.tjitem .picbox{width: 100%;max-height: 186px;overflow: hidden;position: relative;}
.tjitem .pic{display: block;width: 100%;max-height: 186px;transition: .5s;}
.tjitem .picbox:hover .pic{transform: scale(1.4);}



.tjitem .picbox .massk{position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: rgba(0, 0, 0, .5);display: flex;align-items: center;justify-content: center;opacity: 0;transition: .6s;}
.tjitem .picbox:hover .massk{opacity: 1;}


.tjitem h1{font-size: 20px;color: #333;font-weight: bold;margin: 16px 0 20px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;}
.tjitem .bq{display: flex;align-items: center;padding-bottom: 20px;border-bottom: 1px solid #D9D9D9;}
.tjitem .bq p{height: 28px;border: 1px solid #202121;line-height: 26px;border-radius: 14px;padding: 0 12px;color: #333;margin-right: 16px;}
.tjitem .bq .slh{display: flex;align-items: center;}
.tjitem .bq .slh::before,.tjitem .bq .slh:after,.tjitem .bq .slh span{display: block;content: '';width: 4px;height: 4px;border-radius: 100%;background-color: #2F2F2F;}
.tjitem .bq .slh span{margin: 0 8px;}
.tjitem .bq p:last-child{margin-right: 0;}
.tjitem .zzbox{display: flex;align-items: center;padding-top: 20px;}
.tjitem .zzbox .avatar{display: block;width: 34px;height: 34px;border-radius: 100%;margin-right: 12px;}
.tjitem .zzbox .flex{margin-top: 0;padding-left: 0;}
.detailbox .rbox{flex: 1;overflow: hidden;}
.detailbox .xqbox{background-color: #fff;padding: 20px;}

.xqbox .menuBar{display: flex;align-items: center;}
.xqbox .menuBar .lmenu{display: flex;align-items: center;margin-right: auto;}
.xqbox .lmenu p{width: 80px;line-height: 32px;border-radius: 4px;text-align: center;color: #000;cursor: pointer;}
.xqbox .lmenu p.active{background-color: #389589;color: #fff;}
.xqbox .btnbox{display: flex;align-items: center;}
.xqbox .btnbox p{width: 80px;height: 32px;border-radius: 16px;display: flex;align-items: center;
  justify-content: center;color: #F1F6F4;background-color: #389589;cursor: pointer;}
.xqbox .btnbox p img{display: block;width: 16px;height: 16px;margin-right: 6px;}
.xqbox .btnbox .dybtn{background-color: #FF264A;margin-left: 16px;}
.xqbox .tit1{color: #666;display: flex;align-items: center;margin: 30px -20px 20px;}
.xqbox .tit1::before,.abcdpf::before{display: block;content: '';width: 48px;height: 1px;background-color: #DBDEDD;margin-right: 10px;}
.xqbox .tit1::after,.abcdpf::after{display: block;content: '';flex: 1;height: 1px;background-color: #DBDEDD;margin-left: 10px;}

.xqbox .bigImg{display: block;width: 100%;margin-bottom: 20px;border: 2px solid #389589;}
.sltBanner{display: flex;align-items: center;justify-content: center;}
.bd {width: 850px;margin:0 auto;overflow: hidden;}
.basefix:after{clear: both;content: '.';display: block;height: 0;overflow: hidden;}
/* .pro_list_recomm{ float:left; display:inline; width:280px;margin:0 8px;border: 1px solid #fff;padding: 8px;} */
.pro_list_recomm{float:left;display:inline;width: 164px;margin:0 3px;border: 1px solid #fff;padding: 4px;position: relative;}
.pro_list_recomm:hover{ text-decoration:none; border: 1px solid #DBDEDD;}
.pro_list_recomm>img{ display:block; width:100%; max-height: 87px;}
.pro_list_recomm .massk{position: absolute;top: 4px;right: 4px;bottom: 4px;left: 4px;background: rgba(0, 0, 0, .2);display: flex;align-items: center;justify-content: center;opacity: 0;transition: .4s;}
.pro_list_recomm .massk img{display: block;width: 36px;}
.pro_list_recomm:hover .massk{opacity: 1;}
.arrow_l{margin-left: 20px;}
.arrow_r{margin-right: 20px;}
.arrow_l,.arrow_r{height: 32px;width: 32px;}


.liuyBox{margin-top: 24px;padding: 20px 0;border-radius: 4px;background-color: #fff;}
.liuyBox h1{color: #333;font-weight: bold;margin: 0 20px 18px;}
.liuyBox .texta,.wzdpbox .texta{display: block;margin: 0 20px;width: calc(100% - 40px);height: 140px;padding: 20px;background-color: #F4F4F4;}
.liuyBox .fbtn,.wzdpbox .fbtn{width: 180px;line-height: 36px;border-radius: 4px;text-align: center;border-radius: 4px;background-color: #389589;margin: 16px auto 35px;color: #fff;cursor: pointer;}
.liuyBox .plitem{padding: 25px 20px;border-top: 1px solid #DBDEDD;display: flex;align-items: flex-start;}
.liuyBox .plitem .avatar{display: block;width: 48px;height: 48px;border-radius: 100%;margin-right: 16px;}
.liuyBox .plitem .prbox{flex: 1;}
.liuyBox .plitem .prbox .flex{display: flex;align-items: center;margin-bottom: 15px;}
.liuyBox .plitem .flex h2{font-weight: bold;color: #333;margin-right: 27px;}
.liuyBox .plitem .flex p{flex: 1;color: #666;}
.liuyBox .plitem .desc{color: #666;max-width: 600px;line-height: 20px;}


.tabble{display: flex;padding-top: 16px;border-top: 1px solid #D9D9D9;margin-top: 16px;}
.tabble h2{width: 80px;background-color: #3F9EA5;display: flex;
  align-items: center;justify-content: center;margin-right: 4px;font-weight: bold;color: #fff;padding: 0 30px;line-height: 18px;}
.lxmk{
    width: 80px;background-color: #55CFCC;display: flex;
  align-items: center;justify-content: center;margin-right: 4px;font-weight: bold;color: #fff;padding: 0 30px;line-height: 18px;
  }
  .tabble .tablist .itee1{margin-left: 12px;display: flex;align-items: center;height: 36px;background-color: #389589;padding: 5px 10px;position: relative;}
  .tabble .tablist .itee1 .t1{color: #333;line-height: 17px;}
  .tabble .tablist .itee1 .t2{flex: 1;line-height: 26px;padding: 0 9px;background-color: #fff;max-width: 120px;}
  .tabble .tablist .itee1 .t2 input{display: block;width: 100%;height: 26px;}
  .tabble .col2{flex: 1;}
.tabble .row1{display: flex;margin-bottom: 12px;}
.tabble .row1:last-child{margin-bottom: 0;}
.tabble .row1 .titt{width: 49px;font-size: 16px;color: #fff;background-color: #66B9BF;font-weight: bold;padding: 0 20px;display: flex;align-items: center;justify-content: center;line-height: 20px;}
.tabble .tablist{flex: 1;display: flex;flex-wrap: wrap;align-items: flex-start;}
.tabble .tablist .itee{margin-left: 12px;display: flex;align-items: center;height: 36px;background-color: #D6EAEB;padding: 5px 10px;position: relative;}
.tabble .tablist .itee .t1{color: #333;line-height: 17px;}
.tabble .tablist .itee .t2{flex: 1;line-height: 26px;padding: 0 9px;background-color: #fff;max-width: 120px;}
.tabble .tablist .itee .t2 input{display: block;width: 100%;height: 26px;}
.tabble .tablist .item50{width: calc(50% - 12px);margin-bottom: 10px;}
.tabble .tablist .item55{width: calc(50% - -20px);margin-bottom: 10px;}
.tabble .tablist .item45{width: calc(50% - 44px);margin-bottom: 10px;}
.tabble .tablist .item100{width: calc(100% - 12px);}
.tabble .tablist .item100 .t2{max-width: initial;}

.zpfbox{border-radius: 2px;background-color: #363636;padding: 45px 0;display: flex;align-items: center;}
.zpfbox .flex{flex: 1;display: flex;align-items: center;justify-content: center;}
.zpfbox .flex .row{margin-right: 52px;}
.zpfbox .flex .row:last-child{margin-right: 0;}
/* .zpfbox .paibox img{display: block;width: 120px;margin: 0 auto;} */
.zpfbox p{color: #fff;text-align: center;margin-top: 20px;}
.zpfbox .ant-progress-circle .ant-progress-text{color: #fff;font-weight: bold;font-size: 18px;}
.cypfbtn{width: 80px;line-height: 32px;border-radius: 16px;background-color: #389589;text-align: center;color: #fff;margin-left: 38px;}
.abcdpf{display: flex;align-items: center;margin: 30px -20px 20px;}
.abcdpf p{color: #666;margin: 0 15px;padding-bottom: 6px;}
.abcdpf p.active{border-bottom: 2px solid #389589;}

.fzpfbox{background: transparent;padding-bottom: 15px;}
.fzpfbox .row p{color: #666;}
.fzpfbox .ant-progress-circle .ant-progress-text{color: #333;}
.pwpfxqbox{border-top: 24px solid #F4F7F6;margin: 0 -20px;}
.pwpfxqbox .rows{padding: 30px 27px 20px 20px;border-bottom: 1px solid #F4F7F6;display: flex;align-items: center;}
.pwpfxqbox .titbox{border-bottom-color: #DBDEDD;}
.pwpfxqbox .titbox p{color: #333;text-align: center;}
.pwpfxqbox .titbox .pbox1{text-align: left;}
.pwpfxqbox .pbox1{flex: 1;display: flex;align-items: center;}
.pwpfxqbox .pbox1 .avatar{display: block;width: 40px;height: 40px;border-radius: 100%;margin-right: 12px;}
.pwpfxqbox .pbox1 .puinfo{flex: 1;}
.pwpfxqbox .pbox1 .puinfo p{color: #333;}
.pwpfxqbox .pbox1 .puinfo span{display: block;color: #999;margin-top: 10px;}

.pwpfxqbox .pbox2{width: 196px;display: flex;align-items: center;justify-content: center;}
.pwpfxqbox .pbox2 .row{margin-right: 12px;position: relative;}
.pwpfxqbox .pbox2 .row:last-child{margin-right: 0;}
.pwpfxqbox .row:first-child .ant-progress-circle .ant-progress-text{color: #FF6440;font-size: 16px;}
.pwpfxqbox .row:nth-child(2) .ant-progress-circle .ant-progress-text{color: #FADC75;font-size: 16px;}
.pwpfxqbox .row:nth-child(3) .ant-progress-circle .ant-progress-text{color: #409C93;font-size: 16px;}
.pwpfxqbox .row:nth-child(4) .ant-progress-circle .ant-progress-text{color: #03A4F4;font-size: 16px;}
.pwpfxqbox .pbox3{width: 80px;margin: 0 54px 0 14px;text-align: center;}
.pwpfxqbox .zhscore{font-size: 30px;color: #333;}
.pwpfxqbox .pbox4{width: 120px;}
.pwpfxqbox .ckbtn{line-height: 36px;background-color: #389589;color: #fff;text-align: center;cursor: pointer;}
.pwpfxqbox .tips{opacity: 0;transition: .4s;}
.pwpfxqbox .tips p{width: 80px;line-height: 36px;border-radius: 18px;text-align: center;background-color: #389589;color: #fff;position: absolute;left: 50%;margin-left: -40px;top: -48px;}
.pwpfxqbox .tips::after{display: block;content: '';width: 0;height: 0;border-left: 5px solid transparent;border-right: 5px solid transparent;border-top: 8px solid #389589;position: absolute;top: -12px;left: 50%;margin-left: -5px;}
.pwpfxqbox .ant-progress:hover+.tips{opacity: 1;}


.ant-modal-body{background: #F4F7F6;padding: 20px;}
.modal-title{display: flex;align-items: center;height: 44px;border-radius: 4px;background-color: #389589;position: relative;margin-bottom: 50px;}
.modal-title::after{display: block;content: '';width: calc(100% + 40px);height: 1px;background-color: #DBDEDD;position: absolute;left: -20px;bottom: -20px;}
.modal-title p{font-size: 18px;font-weight: bold;color: #fff;flex: 1;text-align: center;}
.modal-title img{display: block;width: 16px;position: absolute;right: 20px;top: 14px;}
.pfxx{border-radius: 4px;overflow: hidden;}
.pfxx .flex{display: flex;align-items: center;height: 80px;padding: 0 30px;}
.pfxx .flex p{color: #fff;font-size: 18px;}
.pfxx .flex .tit{flex: 1;}
.pfxx .numlist{display: flex;background-color: #fff;}
.pfxx .numlist p{flex: 1;border-right: 1px solid #DBDEDD;line-height: 86px;text-align: center;font-size: 20px;font-weight: bold;color: #000;cursor: pointer;}
.pfxx .numlist p:last-child{border-right: 0;}
.pfxx1 .flex{background-color: #FF6440;}
.pfxx1 .numlist p.active{background-color: #EEB4A6;border-right-color: #FF6440;}
.pfxx2 .flex{background-color: #FADC75;}
.pfxx2 .numlist p.active{background-color: #F7EFD2;border-right-color: #EADBA1;}
.pfxx3 .flex{background-color: #409C93;}
.pfxx3 .numlist p.active{background-color: #9DD1CC;border-right-color: #409C93;}
.pfxx4 .flex{background-color: #03A4F4;}
.pfxx4 .numlist p.active{background-color: #8BD9FF;border-right-color: #3A9ED0;}

.resetbtn{width: 140px;height: 32px;border-radius: 4px;border: 1px solid #389589;display: flex;align-items: center;justify-content: center;color: #389589;margin: 0 auto;cursor: pointer;}
.resetbtn::before{display: block;content: '';width: 16px;height: 16px;background-image: url('./imgs/refresh.png');background-size: 100% 100%;margin-right: 12px;}
.wzdpbox h1{font-size: 18px;color: #000;font-weight: normal;margin: 40px 0 20px;}
.wzdpbox .bqlist{display: flex;flex-wrap: wrap;margin-top: -12px;}
.wzdpbox .bqlist p{height: 28px;border-radius: 14px;border: 1px solid #202121;padding: 0 12px;color: #333;line-height: 26px;margin-right: 16px;margin-top: 12px;}
.wzdpbox .bqlist p.active{color: #fff;background-color: #55CFA3;border-color: #55CFA3;}
.wzdpbox .bqlist p:nth-child(4n-2).active{background-color: #55CFBC;border-color: #55CFBC;}
.wzdpbox .bqlist p:nth-child(4n-3).active{background-color: #55CFCC;border-color: #55CFCC;}
.wzdpbox .bqlist p:nth-child(4n).active{background-color: #3F9EA5;border-color: #3F9EA5;}
.wzdpbox .texta{background-color: #ECECEC;border-radius: 4px;height: 200px;}
.wzdpbox .fbtn{width: 100px;margin-bottom: 0;}


.projdbox{display: flex;align-items: center;}
.projdbox .projIcon{display: block;width: 32px;margin-right: 12px;}
.projdbox .zpname{flex: 1;color: #333;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.projdbox .icon{display: block;width: 18px;margin-right: 8px;}
.projdbox .p1{color: #333;margin-right: 42px;}
.projdbox .btn1{width: 140px;height: 42px;border-radius: 4px;background-color: #F4543C;display: flex;align-items: center;justify-content: center;color: #fff;cursor: pointer;}
.projdbox .btn1::before{display: block;content: '';width: 16px;height: 16px;background-image: url('./imgs/down1.png');background-size: 100% 100%;margin-right: 8px;}
.downrule{display: flex;align-items: flex-start;}
.downrule .left1{flex: 1;padding-right: 20px;}
.downrule .left1 .tt1{width: 80px;line-height: 24px;border-radius: 12px;text-align: center;color: #fff;background-color: #333;margin-bottom: 20px;}
.downrule .left1 div p{color: #333;line-height: 36px;}


/* 获奖作品 */
.hqzpItem{background-color: #fff;overflow: hidden;}
.hqzpItem .tit{width: 240px;line-height: 56px;border-radius: 4px;text-align: center;margin: 24px 0 24px 32px;background-color: #389589;font-size: 24px;font-weight: bold;color: #fff;}
.hqzpItem .itemms{display: flex;align-items: center;padding: 48px 126px 56px 66px;border-top: 1px solid #D9D9D9;}
/* .hqzpItem .itemms .pic{display: block;width: 380px;height: 214px;margin-right: 32px;} */


.hqzpItem .itemms .picbox{width: 380px;height: 214px;overflow: hidden;position: relative;margin-right: 32px;}
.hqzpItem .itemms .picbox .massk{position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: rgba(0, 0, 0, .5);display: flex;align-items: center;justify-content: center;opacity: 0;transition: .6s;}
.hqzpItem .itemms .pic{display: block;width: 100%;height: 214px;transition: .5s;}
.hqzpItem .itemms .picbox:hover .pic{transform: scale(1.4);}
.hqzpItem .itemms .picbox:hover .massk{opacity: 1;}


.hqzpItem .itemms .cen{flex: 1;overflow: hidden;}
.hqzpItem .itemms .zpname{font-size: 18px;color: #333;margin-bottom: 22px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.hqzpItem .itemms .bqlist{display: flex;flex-wrap: wrap;}
.hqzpItem .itemms .bqlist p{height: 28px;border-radius: 14px;border: 1px solid #202121;padding: 0 12px;color: #333;margin-right: 16px;margin-bottom: 16px;line-height: 26px;}
.hqzpItem .itemms .flex{display: flex;align-items: center;margin-top: 10px;}
.hqzpItem .itemms .row{margin-right: 20px;}
.hqzpItem .itemms .row:last-child{margin-right: 0;}
.hqzpItem .itemms .row .ant-progress-circle .ant-progress-text{font-size: 18px;font-weight: bold;}
.hqzpItem .itemms .row p{text-align: center;color: #666;margin-top: 12px;}
.hqzpItem .itemms .paibox img{display: block;width: 175px;}
.hqzpItem .itemms .paibox p{text-align: center;color: #333;line-height: 36px;margin-top: 15px;}

/* 作品对比 */
.contbox{background-color: #fff;display: flex;}
.contbox .box1{width: 368px;border-right: 1px solid #D9D9D9;}
.contbox .box2{width: 344px;border-right: 1px solid #D9D9D9;} /*padding: 32px 12px;*/
.contbox .box2:last-child{border-right: 0;}
.contbox .tbox{border-radius: 4px;background-color: #389589;height: 240px;display: flex;align-items: center;justify-content: center;}
.contbox .tbox img{display: block;width: 203px;}
.contbox .pai1{border-bottom: 1px solid #D9D9D9;padding: 32px 12px 54px 24px;}
.contbox .pai2{padding: 32px 12px;border-bottom: 1px solid #D9D9D9;}
.contbox .box1 .pai2:last-child,.contbox .box2 .pai2:last-child{border-bottom: 0;}
.contbox .zpitem{height: 240px;}
.contbox .zhcs{display: flex;}
.contbox .zhcs .h1{width: 80px;padding: 0 30px;background-color: #3F9EA5;font-size: 16px;font-weight: bold;color: #fff;display: flex;align-items: center;justify-content: center;}
.contbox .flex1{width: 58px;margin: 0 8px 0 4px;display: flex;flex-wrap: wrap;}
.contbox .flex1 p{margin-bottom: 10px;display: flex;align-items: center;justify-content: center;width: 58px;padding: 0 20px;font-size: 16px;font-weight: bold;color: #fff;background-color: #66B9BF;}
.contbox .flex1 p:last-child{margin-bottom: 0;}
.contbox .list1{flex: 1;}
.contbox .list1 p{line-height: 36px;text-align: right;background-color: #D6EAEB;color: #333;margin-bottom: 10px;}
.contbox .list1 p:last-child{margin-bottom: 0;}
.contbox .list2 p{padding: 0 12px;text-align: left;}
.contbox .list1 p.active{background-color: #55CFBC;color: #fff;}
.contbox .zpitem .close{display: block;width: 32px;height: 32px;margin-left: auto;}
.contbox .zpitem .pic{display: block;width: 280px;height: 158px;margin: 20px 0 16px;}
.contbox .zpitem  p{font-size: 16px;font-weight: bold;color: #333;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}


.sxmaskbox .ant-modal-body{padding: 0;background-color: #fff;}
.sxmaskbox .tflex{display: flex;align-items: center;background-color: #F4F7F6;height: 100px;padding-right: 20px;border-radius: 4px 4px 0 0;margin-bottom: 16px;}
.sxmaskbox .tflex p{cursor: pointer; width: 200px;line-height: 100px;border-right: 1px solid #D9D9D9;font-size: 20px;color: #333;font-weight: bold;text-align: center;}
.sxmaskbox .tflex p:nth-last-child(2){border-right: 0;margin-right: auto;}
.sxmaskbox .tflex .tits{display: flex;align-items: center;justify-content: center;}
.sxmaskbox .tflex .tits::before{display: block;content: '';width: 20px;height: 20px;background-image: url('./imgs/sx.png');background-size: 100% 100%;margin-right: 10px;}
.sxmaskbox .tflex .active{background-color: #389589;color: #fff;}
.sxmaskbox .flex1{display: flex;border-top: 1px solid #D9D9D9;border-bottom: 1px solid #D9D9D9;padding: 16px 20px;}
.sxmaskbox .flex1 .h2{width: 80px;padding: 0 30px;background-color: #3F8CA5;font-size: 16px;font-weight: bold;color: #fff;display: flex;align-items: center;justify-content: center;}
.sxmaskbox .flex1 .h3{margin: 0 12px 0 4px;width: 58px;padding: 0 20px;font-size: 16px;font-weight: bold;color: #fff;background-color: #5E94A6;display: flex;align-items: center;justify-content: center;}
.sxmaskbox .flex1 .flexwalist{display: flex;flex-wrap: wrap;align-items: flex-start;}
.sxmaskbox .flex1 .flexwalist p{color: #333;line-height: 28px;border-radius: 14px;padding: 0 18px;background-color: #ECECEC;margin-right: 16px;margin-bottom: 12px;}
.sxmaskbox .flex1 .flexwalist .active{color: #fff;background-color: #55CFA3;border-color: #55CFA3;}
.sxmaskbox .flex1 .flexwalist3{display: flex;flex-wrap: wrap;align-items: flex-start;flex: 3;}
.sxmaskbox .flex1 .flexwalist3 .ant-input{height: 100% !important;}
.sxmaskbox .tabble{padding-left: 20px;border-top: 0;border-bottom: 1px solid #D9D9D9;padding-top: 0;padding-bottom: 16px;}
.sxmaskbox .flexxs{display: flex;}
.sxmaskbox .col2{display: flex;}
.sxmaskbox .col2 .row1{margin-bottom: 0;margin-right: 12px;flex: 1;}
.sxmaskbox .colbox .col2{margin-bottom: 10px;}
.sxmaskbox .colbox .col2:last-child{margin-bottom: 0;}

.sxmaskbox .footbtn{display: flex;align-items: center;justify-content: flex-end;padding: 25px 80px 25px 0;}
.sxmaskbox .footbtn p{width: 120px;line-height: 48px;text-align: center;
  font-size: 20px;font-weight: bold;color: #fff;background-color: #389589;margin-left: 24px;border-radius: 24px;cursor: pointer;}


.wdinfobox{display: flex;}
.wdinfobox .lbox{width: 580px;background-color: #fff;padding: 0 24px 24px;}
.wdinfobox .lbox .flex1{display: flex;align-items: center;margin-bottom: 80px;}
.wdinfobox .lbox .avatar{display: block;width: 164px;height: 164px;margin-right: 20px;border-radius: 100%;margin-top: -40px;}
.wdinfobox .lbox .rrbox{flex: 1;}
.wdinfobox .rrbox .flex2{display: flex;align-items: center;}
.wdinfobox .rrbox .flex2 h1{font-size: 36px;font-weight: bold;color: #333;margin-right: 20px;}
.wdinfobox .rrbox .flex2 h3{font-weight: 700;}
h3{font-weight: 700;}
.wdinfobox .rrbox .flex2 img{display: block;width: 96px;height: 28px;}
.wdinfobox .rrbox .p2{font-size: 20px;color: #343535;margin-top: 20px;}
.wdinfobox .lbox .pp1{font-size: 20px;color: #343535;margin-top: 36px;}
.wdinfobox .lbox .flex3{display: flex;align-items: center;margin-top: 100px;}
.wdinfobox .lbox .flex3 p{flex: 1;line-height: 74px;border-radius: 4px;font-size: 20px;color: #fff;background-color: #389589;margin-right: 12px;text-align: center;}
.wdinfobox .lbox .flex3 p:last-child{margin-right: 0;}
.wdinfobox .lbox .flex3{cursor:pointer;}
.wdinfobox .rbox{flex: 1;margin-left: 24px;background-color: #fff;}
.wdinfobox .rbox .pic{display: block;width: 100%;height: 448px;}
.wdinfobox .rbox .flex1{display: flex;align-items: center;padding: 20px 24px 0;}
.wdinfobox .rbox .flex1 .avatar{display: block;width: 46px;height: 46px;border-radius: 100%;margin-right: 20px;}
.wdinfobox .rbox .flex1 .cen{flex: 1;}
.wdinfobox .rbox .flex1 .cen h1{font-size: 20px;font-weight: bold;color: #202121;}
.wdinfobox .rbox .flex1 .cen p{color: #4A4D4B;margin-top: 10px;}
.wdinfobox .btnbox{display: flex;align-items: center;}
.wdinfobox .btnbox p{width: 80px;height: 32px;border-radius: 16px;
  display: flex;align-items: center;justify-content: center;color: #F1F6F4;background-color: #F4543C;cursor: pointer;}
.wdinfobox .btnbox p img{display: block;width: 16px;height: 16px;margin-right: 6px;}
.wdinfobox .btnbox .dybtn{background-color: #FF264A;margin-left: 16px;}
.upflexx{display: flex;margin-top: 50px;}
.upflexx p{width: 180px;text-align: center;line-height: 52px;font-size: 24px;color: #333;margin-top: 0 !important;cursor:pointer;}


.personalData{width: 900px;margin: 60px auto 140px;background-color: #fff;overflow: hidden;}
.personalData .tit{border-bottom: 1px solid #DBDEDD;padding: 27px 0 24px 30px;position: relative;font-size: 20px;color: #000;}
.personalData .tit::after{display: block;content: '';width: 80px;height: 4px;background-color: #389589;position: absolute;bottom: -1px;left: 30px;}
.personalData .avatar{display: block;width: 120px;height: 120px;border-radius: 100%;margin: 40px auto 60px;}
.personalData .jb1{color: #000;margin: 0 0 0 30px;}
.personalData .flex{display: flex;margin: 30px 0 0 30px;align-items: center;}
.personalData .p1{width: 60px;text-align: right;color: #999;margin-right: 24px;}
.personalData .inpt{display: block;width: 200px;height: 42px;border-radius: 4px;background-color: #F4F7F6;padding: 0 20px;}
.personalData .p2{width: 200px;padding: 0 20px;line-height: 42px;}
.personalData .p3{margin-left: 24px;color: #389589;cursor: pointer;}
.personalData textarea{display: block;flex: 1;height: 42px;border-radius: 4px;background-color: #F4F7F6;padding: 10px 20px;}
.personalData .save{width: 100px;line-height: 42px;border-radius: 4px;text-align: center;margin: 70px 50px 20px auto;color: #fff;background-color: #389589;cursor: pointer;}

/* 产品手册 */
.banner1{display: block;height: 400px;width: 100%;}
.probox{display: flex;}
.probox .lmenu{width: 200px;background-color: #F4F7F6;padding-top: 70px;}
.probox .lmenu p{font-size: 20px;color: #333;padding-bottom: 36px;cursor: pointer;}
.probox .lmenu p.active{color: #389589;}
.probox .lmenu .mm1{padding-left: 24px;}
.probox .lmenu .mm2{padding-left: 20px;}
.probox .lmenu .mm3{padding-left: 20px;}
.probox .rbox{padding: 27px 50px 20px 50px;}


.telbox{width: 1000px;margin: 120px auto 0;}
.telbox .flex{display: flex;align-items: flex-end;margin-bottom: 15px;}
.telbox .flex .p1{margin-left: auto;color: #389589;cursor: pointer;}
.telbox .tit{line-height: 80px;border-radius: 4px 4px 0 0;text-align: center;background-color: #389589;font-size: 20px;color: #fff;}
.telbox .bgbai{padding: 50px 320px 86px;background-color: #fff;border-radius: 0 0 4px 4px;}
.telbox .bgbai .inpt{display: block;width: 100%;height: 40px;border-radius: 4px;border: 1px solid #DBDEDD;padding: 0 16px;margin-bottom: 24px;}
.telbox .bgbai .flex1{
  display: flex;
  align-items: center;
  /* margin-right: 80px; */
  margin-right: 65px;
  margin-bottom: 24px;
}
.telbox .flex1 input{display: block;flex: 1;height: 40px;border-radius: 4px;border: 1px solid #DBDEDD;padding: 0 16px;}
.telbox .flex1 .btn{width: 90px;line-height: 40px;border-radius: 4px;text-align: center;background-color: #389589;color: #fff;margin-left: 8px;}
.telbox .qbtn{line-height: 40px;border-radius: 4px;text-align: center;
  background-color: #389589;font-size: 16px;color: #fff;margin-top: 42px;cursor: pointer;}
.telbox .imgbox{padding: 20px 16px;border-radius: 4px;border: 1px solid #DBDEDD;}
.telbox .imgbox .top1{position: absolute;width: 20px;height: 20px;top: 10px;right: -8px;}
.telbox .imgbox div{position: relative;width: 100px;}
.telbox .imgbox p{font-size: 18px;color: #333;margin-bottom: 20px;}
.telbox .imgbox .pic{display: block;width: 100px;height: 100px;border-radius: 4px;}
.telbox .imgbox .close{position: absolute;width: 20px;height: 20px;top: -8px;right: -8px;}
.telbox .gou{display: block;width: 100px;margin: 70px auto 72px;}
.telbox .p11{text-align: center;font-size: 16px;color: #333;}

.integflexbox{display: flex;align-items: flex-start;margin-bottom: 160px;}
.integflexbox .lbox{width: 300px;height: 500px;margin-right: 24px;padding: 24px 24px 0;background-color: #fff;border-radius: 4px;}
.integflexbox .lbox .flex{display: flex;align-items: center;width: 250px;height: 154px;border-radius: 10px;background-color: #389589;position: relative;padding-left: 30px;margin-bottom: 21px;}
.integflexbox .lbox .bg{display: block;width: 100%;position: absolute;left: 0;top: 0;right: 0;bottom: 0;}
.integflexbox .lbox .nbox{flex: 1;margin-left: 24px;}
.integflexbox .lbox .nbox h1{color: #fff;font-size: 24px;margin-bottom: 15px;font-weight: bold;}
.integflexbox .lbox .nbox h2{color: #fff;}
.integflexbox .m1{height: 48px;border-radius: 4px;padding: 0 26px;display: flex;align-items: center;padding: 0 26px;color: #333;cursor: pointer;}
.integflexbox .m1::before{display: block;content: '';width: 2px;height: 12px;background-color: #333;margin-right: 8px;}
.integflexbox .m1.active{color: #fff;background-color: #389589;}
.integflexbox .m1.active::before{background-color: #fff;}
.integflexbox .rbox{flex: 1;background-color: #fff;border-radius: 4px;padding: 24px;}
.integflexbox .rbox .h1{font-size: 24px;font-weight: bold;color: #333;padding-top: 12px;}
.integflexbox .rbox .flex{display: flex;align-items: center;margin: 24px 0 20px;}
.integflexbox .rbox .flex p{width: 120px;line-height: 36px;border-radius: 4px;text-align: center;font-size: 16px;cursor: pointer;}
.integflexbox .rbox .flex p.active{background-color: #389589;color: #fff;}

.ant-table-thead > tr > th{background-color: #EEF1F0;color: #333;font-size: 14px;font-weight: normal;}
.ant-table-tbody > tr > td{color: #666;}
.ant-table-thead > tr > th,.ant-table-tbody > tr > td{border-bottom: 0;}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{display: none;}
.ant-table-tbody > tr.ant-table-row:hover > td{background-color: inherit;}
.ant-table-tbody > tr.ant-table-row:nth-child(2n)>td{background-color: #F4F7F6;}
.ant-table-tbody .btnbox{display: flex;align-items: center;justify-content: center;}
.ant-table-tbody .btnbox p{width: 80px;line-height: 32px;border-radius: 4px;text-align: center;background-color: #389589;color: #fff;}
.ant-table-tbody .btnbox .b2{margin-left: 16px;background-color: #F4543C;}

.integflexbox .cz1{margin: 40px 0 30px;}
.integflexbox .czlist{display: flex;flex-wrap: wrap;}
.integflexbox .czitem{width: 135px;height: 70px;border-radius: 4px;border: 1px solid #389589;margin: 14px 24px 0 0;cursor: pointer;}
.integflexbox .czitem h2{text-align: center;font-size: 20px;font-weight: bold;margin: 12px 0 10px;}
.integflexbox .czitem p{color: #999;text-align: center;}
.integflexbox .czitem.active{background-color: #389589;}
.integflexbox .czitem.active h2,.integflexbox .czitem.active p{color: #fff;}
.integflexbox .cz2{margin: 32px 0 16px;}
.integflexbox .inptt{display: block;width: 600px;border-bottom: 1px solid #D9D9D9;font-size: 20px;font-weight: bold;padding-bottom: 6px;}
.integflexbox .cz3{color: #999;margin: 20px 0 30px;line-height: 24px;max-width: 600px;}
.integflexbox .typebox{display: flex;}
.integflexbox .typebox div{width: 227px;height: 100px;border-radius: 6px;border: 1px solid #D9D9D9;display: flex;align-items: center;justify-content: center;margin-right: 12px;cursor: pointer;}
.integflexbox .typebox div.active{border-color: #389589;}
.integflexbox .typebox div img{display: block;width: 28px;margin-right: 12px;}
.integflexbox .czbtn{width: 94px;line-height: 36px;border-radius: 4px;text-align: center;color: #fff;background-color: #389589;margin-top: 40px;cursor: pointer;}

.paymask .modal-title::after{width: 600px;}
.paymask .pri{text-align: center;}
.paymask .pri span{font-size: 20px;font-weight: bold;color: #FF264A}
.paymask .ewm{width: 140px;height: 140px;border: 1px solid #DBDEDD;padding: 10px;margin: 20px auto;}
.paymask .ewm img{display: block;width: 100%;height: 100%;}
.paymask .btn{width: 100px;height: 32px;line-height: 30px;text-align: center;border: 1px solid #389589;color: #389589;border-radius: 4px;margin: 40px auto 0;cursor: pointer;}
.paymask .flex1{display: flex;align-items: center;justify-content: center;}
.paymask .flex1 img{display: block;width: 20px;margin-right: 8px;}
.paymask .gou{display: block;width: 100px;margin: 80px auto 100px;}

.paymask .flex{display: flex;justify-content: center;}
.paymask .flex .btn{margin: 40px 0 0;}
.paymask .flex .btn:nth-child(1){margin-right: 16px;background: #389589;color: #fff;}


.paymask .kflex{display: flex;align-items: flex-start;}
.paymask .kflex .ittem{flex: 1;}
.paymask .kflex .ittem h3{color: #999;margin-bottom: 18px;}
.paymask .kflex .ittem .ewm{margin: 0;}
.paymask .kflex .ittem p{color: #000;}

.paymask .telbox{width: 100%;margin-top: 24px;border: 1px solid #DBDEDD;border-radius: 4px;}
.paymask .telbox .imgbox{display: flex;flex-wrap: wrap;border: 0;padding-top: 0;}
.paymask .telbox .imgbox div{margin: 20px 30px 0 0;}
.paymask .telbox .txtt{display: block;width: 100%;height: 160px;padding: 20px;line-height: 20px;outline: none;resize: none;}

.loginbox .ant-modal-body{padding: 0;}
.loginbox .flex{display: flex;}
.loginbox .flex .bg{display: block;width: 340px;height: 440px;}
.loginbox .flex .rbox{flex: 1;padding: 40px 42px 0;}
.loginbox .flex1{display: flex;margin-bottom: 56px;}
.loginbox .flex1 p{font-size: 20px;font-weight: bold;color: #666;cursor: pointer;}
.loginbox .flex1 p:first-child{margin-right: auto;}
.loginbox .flex1 .active{color: #389589;}
.loginbox .inptbox{display: flex;align-items: center;margin-bottom: 24px;}
.loginbox .inptbox input{flex: 1;height: 40px;border-radius: 4px;border: 1px solid #DBDEDD;padding: 0 20px;font-size: 16px;}
.loginbox .inptbox .code{width: 90px;line-height: 40px;border-radius: 4px;text-align: center;color: #fff;background-color: #389589;margin-left: 8px;}
.loginbox .bcbox,.telbox .bcbox{display: flex;align-items: center;cursor: pointer;}
.loginbox .bcbox span,.telbox .bcbox>span{display: block;width: 16px;height: 16px;border-radius: 2px;background-color: #CFD0D0;}
.loginbox .bcbox img,.telbox .bcbox img{display: block;width: 16px;height: 16px;}
.loginbox .bcbox p,.telbox .bcbox p{flex: 1;margin-left: 8px;line-height: 16px;}
.loginbox .loginbtn{line-height: 40px;border-radius: 4px;text-align: center;background-color: #389589;font-size: 16px;color: #fff;margin-top: 44px;cursor: pointer;}
.loginbox .flex3{display: flex;align-items: center;justify-content: center;margin-top: 10px;}
.loginbox .flex3 p{color: #999;cursor: pointer;}
.loginbox .flex3 p:first-child{color: #389589;display: flex;align-items: center;}
.loginbox .flex3 p:first-child::after{display: block;content: '';width: 1px;height: 12px;background-color: #DBDEDD;margin: 0 12px;}

.telbox .flex2{display: flex;align-items: center;height: 40px;border-radius: 4px;border: 1px solid #DBDEDD;padding: 0 20px;margin-bottom: 12px;}
.telbox .flex2 input{display: block;flex: 1;}
.telbox .flex2 img{display: block;width: 20px;}
.telbox .bcbox p span{color: #389589;}
.telbox .p2{display: flex;align-items: center;color: #000;margin-left: auto;}
.telbox .p2::after{display: block;content: '';width: 1px;height: 10px;background-color: #333;margin: 0 16px;}
.telbox .p2 span{color: #389589;cursor: pointer;}


.ant-select:not(.ant-select-customize-input) .ant-select-selector{border: 0;padding: 0;}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector{border: 0;}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{height: 26px;}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{height: 26px;}
.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{line-height: 26px;}

.ant-select-arrow{display: flex;align-items: center;justify-content: center;}
.xsj{display: block;width: 0;height: 0;border-left: 4px solid transparent;border-right: 4px solid transparent;border-top: 4px solid #000;}

.mar_10{margin: 0 10px;}
.mar_2{margin: 0 4px;color: #fff !important;}
.mar_3{margin: 0 4px;color: #000 !important;}
.color_white{color: #fff !important;}
.mar_bot{margin-bottom: 0 !important;}
.mar_bot10{margin-bottom: 10px !important;}

.ovauto{max-height: 60vh;overflow: scroll;}
.ovauto::-webkit-scrollbar{display: none;}

.sxmaskbox .col3{display: flex;flex-wrap: wrap;align-items: flex-start;}
.sxmaskbox .col3 .row1{flex: none;width: calc(50% - 12px);margin-right: 12px;margin-bottom: 10px;}
.sxmaskbox .col3 .row1:last-child,.sxmaskbox .col3 .row1:nth-last-child(2){margin-bottom: 0;}

.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 99999999999;
  width: 100%;
  pointer-events: none;
}
.pwpfxqbox .row:first-child .ant-progress-circle .ant-progress-text {
  color: #FF6440;
  font-size: 13px;
}
.pwpfxqbox .row:nth-child(2) .ant-progress-circle .ant-progress-text {
  color: #FADC75;
  font-size: 13px;
}
.pwpfxqbox .row:nth-child(3) .ant-progress-circle .ant-progress-text {
  color: #409C93;
  font-size: 13px;
}
.pwpfxqbox .row:nth-child(4) .ant-progress-circle .ant-progress-text {
  color: #03A4F4;
  font-size: 13px;
}

.ckMask .numlist{display: flex;flex-wrap: wrap;}
.ckMask .numlist p{line-height: 28px;padding: 0 12px;border-radius: 14px;color: #fff;background: #55CFA3;margin-right: 16px;margin-bottom: 16px;}
.ckMask .numlist p:nth-child(4n-2){background: #55CFBC;}
.ckMask .numlist p:nth-child(4n-3){background: #55CFCC;}
.ckMask .numlist p:nth-child(4n){background: #3F9EA5;}
.ckMask .pp11{line-height: 24px;padding-top: 15px;}
.ckMask .fbtn{width: 100px;height: 32px;border-radius: 4px;text-align: center;line-height: 30px;border: 1px solid #389589;color: #389589;margin: 60px auto 0;cursor: pointer;}

/* 作品对比 */
.zpdbBox{width: 140px;border-radius: 4px;background-color: #fff;position: fixed;right: 100px;top: 260px;}
.zpdbBox h2{text-align: center;border-bottom: 1px solid #DBDEDD;height: 43px;line-height: 42px;font-weight: bold;color: #333;font-size: 16px;}
.zpdbBox>div{border-bottom: 1px dashed #DBDEDD;padding: 12px 0;margin: 0 20px 0 14px;position: relative;}
.zpdbBox .zpname{line-height: 24px;display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;}
.zpdbBox .del{display: block;width: 10px;height: 10px;position: absolute;bottom: 18px;right: -14px;cursor: pointer;}
.zpdbBox .zpbtn{width: 110px;height: 32px;border-radius: 4px;background: #389589;text-align: center;line-height: 32px;color: #fff;margin: 24px auto;cursor: pointer;}



.ant-upload.ant-upload-select{display: block;margin: 0 auto;}

.Mcont .name{cursor: pointer;}


.ant-pagination{text-align: center;margin-bottom: 40px;}
.ant-pagination-item-active{border-color: #389589;background: #389589;}
.ant-pagination-item-active a{color: #fff;}
.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next,.ant-pagination-item,.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{border-radius: 4px;}
.ant-pagination-item-active:focus-visible, .ant-pagination-item-active:hover,.ant-pagination-item:focus-visible, .ant-pagination-item:hover{border-color: #389589;}
.ant-pagination-item-active:focus-visible a, .ant-pagination-item-active:hover a,.ant-pagination-item:focus-visible a, .ant-pagination-item:hover a{color: #fff;}
.ant-pagination-item-active:focus-visible, .ant-pagination-item-active:hover, .ant-pagination-item:focus-visible, .ant-pagination-item:hover{background: #389589;}
.ant-pagination-prev:focus-visible .ant-pagination-item-link, .ant-pagination-next:focus-visible .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link{border-color: #389589;background: #389589;color: #fff;}
.ant-empty{margin-top: 60px;}

.sdxcv{
  display: flex;
  justify-content: center;
  /* margin-left: 50%; */
  margin-top: 30px;
}
.sdxcv .more{width: 78px;line-height: 32px;height: 32px;border-radius: 4px;text-align: center;background: #389589;color: #fff;margin-left: 24px;cursor: pointer;}

.ant-carousel .slick-dots li.slick-active{
  color: #389589!important;
}
.ant-carousel .slick-dots li button{
   background:#389589!important;
   height: 6px!important;
 }


.top{
  margin-top: 40px;;
}

.title{
  color: #333333;
  font-weight: bold;
  font-size: 20px;
}
.telbox .flex1 .btn{
  cursor: pointer;
}

.cypfbtn{
  cursor: pointer;
}

.code{
  cursor: pointer;
}
.pjList .item .flexw{
  /* height:88px; */
  height:45px;
  align-items:flex-start;
}
.title{
  width: 230px;
}

.csjhjh.wdinfo .flex{
  flex-wrap: wrap;
}
.csjhjh.wdinfo .flex p{
  width: 50%;
  margin-bottom: 15px;
}

.cksy{
  margin-right: 90%;
}

.active111111 {
  background-color: #389589;
  line-height: 32px;
  width: 80px;
  text-align: center;
  border-radius: 4px;
  color: #fff;
}

.downxz{
  width: 378.67px;
}

.h3C{
  margin-left: 40px;
}